import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Image from "gatsby-image"
import HeaderSmall from "./headerSmall"
import SEO from "./seo"
import ComparisonTable from "./comparisonTable"


const InsuranceDetailsSection = ({type,path,product,provider,rating,year,aspects,table,providerUrl}) => (

    <StaticQuery query={graphql`
          query{
              site {
                  siteMetadata {
                      title,
                      year,
                      guideyear
                  }
              },
              defaqto0: file(relativePath: { eq: "ratings/rating0.jpg" }) {
                  childImageSharp {
                      fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid
                      }
                  }
              },
              defaqto1: file(relativePath: { eq: "ratings/rating1.jpg" }) {
                  childImageSharp {
                      fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid
                      }
                  }
              },
              defaqto2: file(relativePath: { eq: "ratings/rating2.jpg" }) {
                  childImageSharp {
                      fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid
                      }
                  }
              },
              defaqto3: file(relativePath: { eq: "ratings/rating3.jpg" }) {
                  childImageSharp {
                      fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid
                      }
                  }
              },
              defaqto4: file(relativePath: { eq: "ratings/rating4.jpg" }) {
                  childImageSharp {
                      fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid
                      }
                  }
              },
              defaqto5: file(relativePath: { eq: "ratings/rating5.jpg" }) {
                  childImageSharp {
                      fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid
                      }
                  }
              },
          }
      `}
      render={data => (
        <>
          <SEO
            title={product + " fra " + provider + " | Test av "+type+ " "+year}
            description={"Vurdering av kvalitet på vilkår og dekninger på " + product + " fra " + provider + ". Sammenlign "+product+" fra "+provider+" med andre tilbydere av "+type+"er."}
            schema={`
                  {
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Bestforsikring.no",
                    "item": "https://bestforsikring.no"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Vurdering av `+ provider+`",
                    "item": "https://bestforsikring.no/`+slugifier(provider)+`"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Vurdering av `+type+`en `+product+` fra `+provider+`",
                    "item": "https://bestforsikring.no/`+path+`/`+slugifier(provider)+`/`+slugifier(product)+`"
                  }]
                }
             `}

            productSchema={`
              {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": "`+ provider +` `+product+` (`+type+`)",
                "sku":"`+firstToUpper(type)+`:`+provider+`:`+product+`",
                "brand": {
                  "@type": "Organization",
                  "name": "`+provider+`"
                },
                "description": "`+firstToUpper(type)+`en `+product+` fra `+provider+`",
                "image": "https://bestforsikring.no/_images/vurdering.jpg",
                "category": "Forsikring / `+firstToUpper(type)+`",
                "review": {
                  "@type": "Review",
                  "author": {
                    "@type": "Organization",
                    "name": "Defaqto Norge",
                    "url": "https://defaqto.no"
                  },
                  "publisher": {
                    "@type": "Organization",
                    "name": "Bestforsikring.no",
                    "url": "https://bestforsikring.no"
                  },
                  "datePublished": "`+year+`-01-05T00:00:00.000Z",
                  "description": "Test av `+type+`en `+product+` som tilbys av `+provider+`. Hvor gode er vilkårene og hvor godt dekker denne forsikringen ved skade? Oppdatert `+year+`.",
                  "url": "https://bestforsikring.no/`+path+`/`+slugifier(provider)+`/`+slugifier(product)+`",
                  "reviewBody": "`+firstToUpper(product)+` fra `+provider+`: Denne `+type+`en er i `+year+` vurdert til `+rating+` Defaqto stjerner, basert på en analyse av `+aspects+` egenskaper og dekningsgraden til forsikringen.",
                  "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "`+rating+`",
                    "worstRating": "1",
                    "bestRating": "5",
                    "reviewAspect": "Kvalitet på vilkår og dekninger"
                  }                               
                }                            
              }
            `}
          />
          <HeaderSmall siteSection={path}/>
          <div className="moto-widget moto-widget-row row-fixed moto-bg-color5_5 moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top" data-draggable-disabled>
            <div className="container-fluid">
              <div className="row" data-container="container">
                <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                  <div className="krPW moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                    <div>
                      <h3 style={{fontSize:'110%',paddingBottom:20}} className="moto-text_system_7"><Link to={"/"+path}>{firstToUpper(type)}er</Link> > <> </>
                        {/*<Link to="/bil/2020">2020</Link> > <> </>*/}
                        <Link to={`/${slugifier(provider)}#`+path}>{provider}</Link> > <> </>
                        <Link to={`/`+path+`/${slugifier(provider)}/${slugifier(product)}`}>{product}</Link>
                      </h3>

                      <h1>Vurdering av {product} fra {provider} ({year})</h1>

                      <p style={{paddingTop:'20px',paddingBottom:'20px',fontSize:'120%',fontWeight:'600'}}>
                        Hvor godt dekker <strong>{type}en "{product}"</strong>&nbsp;som
                        tilbys av <Link to={`/${slugifier(provider)}#`+path}>{provider}</Link>?
                        Hvor gode er vilkårene og hvor godt dekker denne forsikringen ved skade?
                      </p>

                      <Image fluid={(rating)?((rating==5)?data.defaqto5.childImageSharp.fluid:(rating==4)?data.defaqto4.childImageSharp.fluid:(rating==3)?data.defaqto3.childImageSharp.fluid:(rating==2)?data.defaqto2.childImageSharp.fluid:(rating==1)?data.defaqto1.childImageSharp.fluid:data.defaqto0.childImageSharp.fluid):data.defaqto0.childImageSharp.fluid}/>

                      <p style={{fontSize: '120%',paddingTop:'20px'}}>
                        {rating !== "0" && <>
                          Denne forsikringen er vurdert til <strong><span style={{textDecoration:'underline'}}>{rating} Defaqto stjerner</span></strong> av bransjeeksperter innen forsikring.
                          {rating == "5" && <> Det vil si at dette er en av forsikringene som er kåret til <strong>best i test {type}</strong> av de som har blitt
                            testet i {year}.</>}
                        </>
                        }
                        {rating === "0" && <>Forsikringen er <strong>kun en grunnleggende dekning</strong> hvor forskjellen fra selskap til selskap er veldig liten. Defaqto har derfor <strong>ikke vurdert</strong> denne forsikringen.</>}
                      </p>
                      <br/>
                      <p>
                        Vurderingen av {type}ene er faktabasert og legger til grunn
                        en grundig analyse av vilkår og dekningssummer.
                      </p>
                    </div>


                    <div className="moto-spacing-top-medium">
                      <h2>Hvor god er {type}en {product} fra {provider}?</h2>
                      <p>
                        Alle forsikringene vi tester gis en vurdering  fra 1 til 5 Defaqto stjerner, hvor 5 stjerner er best.

                        {rating !== "0" &&
                        <>
                          &nbsp;Denne forsikringen fra {provider} er, med sine <strong>{rating} stjerner</strong>, vurdert til å være
                          {rating === "5" && <> <strong>blant de aller beste {type}ene</strong>.</>}
                          {rating === "4" && <> <strong>en veldig god {type}</strong>.</>}
                          {rating === "3" && <> <strong>en god {type}</strong>.</>}
                          {rating === "2" && <> <strong>en middels god {type}</strong>.</>}
                          {rating === "1" && <> <strong>en dårlig {type}</strong>.</>}

                        </>
                        }
                      </p>
                      <br/>
                      <p>
                        For å vurdere om forsikringen er god nok for ditt behov kan du ta en titt på vår oppdaterte <Link to={"/"+path+"/"+year+"/guide"}>guide til kjøp av {type}</Link>.
                      </p>
                    </div>

                    {/*<div className="moto-spacing-top-medium">
                    <h2>Fakta om forsikringen:</h2>
                    <p>

                    </p>
                  </div>*/}

                    <div className="moto-spacing-top-medium">
                      <h2>Best i test {type}er:</h2>
                      <p>
                        Finnes det bedre alternativer? Hvilke {type}er er best i test {year}? Under er en oversikt over {type}er som er bedre eller like gode som <strong>{product}</strong> fra {provider}:
                      </p>
                      <br/>
                      <div className="krTDiv moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                        <ComparisonTable objList={table} siteSection={path} betterThan={parseInt(rating)}/>
                      </div>
                    </div>

                    <div className="moto-spacing-top-medium">
                      <h2>Fakta om {provider}:</h2>
                      <p>
                        <Link to={`/${slugifier(provider)}`}>{provider}</Link> er et forsikringsselskap som tilbyr privatforsikringer i det norske markedet.
                      </p>
                    </div>


                    <div className="moto-spacing-top-medium">
                      <div data-widget-id="wid_1553425835_8mmnt722a" className="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-medium  " data-widget="button">
                        <Link style={{marginLeft:20, marginRight:20, marginTop:20}} to={"/"+path+"/sammenlign"} data-action="page" className="moto-widget-button-link moto-size-medium moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">Sammenlign {type}er</span></Link>
                      </div>
                    </div>


                    <div className="moto-spacing-top-medium">
                      <h2>Efaringer med {type} fra {provider}:</h2>
                      <p>
                        Vi har per i dag ikke fått inn noen kundeerfaringer fra
                        kunder på {product}-forsikringen fra {provider}.
                      </p>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
      )}
    />

)

function firstToUpper(s){
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function slugifier(input) {

  let slugString = input;

  return slugString
    //.toLowerCase()
    .replace(/æ/g,'ae')
    .replace(/ø/g,'o')
    .replace(/å/g,'aa')
    .replace(/&/g,'og')
    .replace(/[^a-zA-Z0-9\/]+/g, '-')
    .replace(/\/-/g,'/')
    .slice(0, 96)                                             // Setting max length to 96
    .replace(/-$/g,'')
}

export default InsuranceDetailsSection